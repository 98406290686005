class WebSocketManager {
  constructor() {
    if (WebSocketManager.instance) {
      return WebSocketManager.instance;
    }
    this.url = window.location.hostname === 'fsm.name' ? 'wss://fsm.name/ws/' : 'wss://dev.fsm.name/ws/';
    this.ws = null;
    this.isConnected = false;
    this.reconnectAttempts = 0;
    this.maxReconnectAttempts = 10;
    this.reconnectInterval = 500;
    this.heartbeatInterval = 10000;
    this.heartbeatTimer = null;
    this.broadcastInterval = 500;
    this.broadcastTimer = null;

    this.checkInterval = 3000;
    this.maxDelay = 3000;

    this.tabId = this.generateTabId();

    this.lastHeartbeat = Date.now() - 1000;

    this.channel = new BroadcastChannel('ws_channel');
    this.channel.onmessage = this.handleChannelMessage.bind(this);

    window.addEventListener('beforeunload', this.cleanup.bind(this));

    WebSocketManager.instance = this;
  }

  connect() {
    const currentTime = Date.now();
    const wsConnected = localStorage.getItem('ws_connected');

    if (!wsConnected || (wsConnected !== this.tabId && currentTime - this.lastHeartbeat > this.maxDelay)) {
      this.createWebSocket();
    } else {
      this.startCheck();
    }
  }

  createWebSocket() {
    this.ws = new WebSocket(this.url);

    this.ws.onopen = () => {
      this.isConnected = true;
      this.reconnectAttempts = 0;
      localStorage.setItem('ws_connected', this.tabId);
      this.channel.postMessage({ type: 'connected', tabId: this.tabId });
      this.startServerHeartbeat();
      this.startBroadcastHeartbeat();
    };

    this.ws.onmessage = (event) => {
      try {
        let res = JSON.parse(event.data);
        let deviceId = localStorage.getItem('DeviceId');
        if (deviceId !== res.deviceId) {
          localStorage.setItem('DeviceId', res.deviceId);
        }
      } catch (error) {
        console.error('消息解析错误:', error);
      }
    };

    this.ws.onclose = () => {
      this.cleanup();
      this.reconnect();
    };

    this.ws.onerror = (error) => {
      this.isConnected = false;
      if (this.ws) {
        this.ws.close();
      }
    };
  }

  reconnect() {
    const currentTime = Date.now();
    this.reconnectAttempts++;
    if (this.reconnectAttempts >= this.maxReconnectAttempts) {
      return;
    }

    const reconnectDelay = Math.min(this.reconnectInterval * this.reconnectAttempts, 5000);

    setTimeout(() => {
      const wsConnected = localStorage.getItem('ws_connected');
      if (!wsConnected || wsConnected === this.tabId || currentTime - this.lastHeartbeat > this.maxDelay) {
        this.createWebSocket();
      } else {
        this.startCheck();
      }
    }, reconnectDelay);
  }

  send(action) {
    if (!this.ws || this.ws.readyState !== WebSocket.OPEN) {
      return;
    }

    const timestamp = new Date().getTime();
    try {
      this.ws.send(JSON.stringify({
        action: action,
        token: localStorage.getItem('token'),
        ts: Math.floor(timestamp / 1000)
      }));
    } catch (error) {
      this.isConnected = false;
      this.reconnect();
    }
  }

  close() {
    if (this.ws) {
      this.ws.close();
      this.ws = null;
      this.channel.postMessage({ type: 'disconnecting', tabId: this.tabId });
      this.stopServerHeartbeat();
      this.stopBroadcastHeartbeat();
    }
  }

  startServerHeartbeat() {
    const sendHeartbeat = () => {
      if (this.isConnected && this.ws) {
        this.send('PING');
      } else {
        this.reconnect();
        return;
      }
      this.heartbeatTimer = setTimeout(sendHeartbeat, this.heartbeatInterval);
    };
    if (!this.heartbeatTimer) {
      sendHeartbeat();
    }

  }

  stopServerHeartbeat() {
    if (this.heartbeatTimer) {
      clearTimeout(this.heartbeatTimer);
      this.heartbeatTimer = null;
    }
  }

  startBroadcastHeartbeat() {
    const sendBroadcast = () => {
      this.channel.postMessage({ type: 'heartbeat', tabId: this.tabId });
      this.broadcastTimer = setTimeout(sendBroadcast, this.broadcastInterval);
    };
    if (!this.broadcastTimer) {
      sendBroadcast();
    }
  }

  stopBroadcastHeartbeat() {
    if (this.broadcastTimer) {
      clearTimeout(this.broadcastTimer);
      this.broadcastTimer = null;
    }
  }

  startCheck() {
    const checkStatus = () => {
      const currentTime = Date.now();

      if (this.tabId !== localStorage.getItem('ws_connected')) {
        if (currentTime - this.lastHeartbeat > this.maxDelay) {
          this.connect();
        }
      }

      this.checkTimer = setTimeout(checkStatus, this.checkInterval);
    };
    if (!this.checkTimer) {
      checkStatus();
    }
  }

  stopCheck() {
    if (this.checkTimer) {
      clearTimeout(this.checkTimer);
      this.checkTimer = null;
    }
  }

  handleChannelMessage(event) {
    const message = event.data;

    if (message.type === 'connected' && message.tabId !== this.tabId) {
      this.close();
      this.startCheck();
    } else if (message.type === 'heartbeat') {
      if (message.tabId !== this.tabId) {
        this.lastHeartbeat = Date.now();
      }
    } else if (message.type === 'disconnecting' && message.tabId === localStorage.getItem('ws_connected')) {
      this.createWebSocket();
    }
  }

  cleanup() {
    if (this.ws) {
      this.ws.close();
    }
    this.stopServerHeartbeat();
    this.stopBroadcastHeartbeat();
    this.stopCheck();
  }

  generateTabId() {
    return `${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;
  }
}

const wsManager = new WebSocketManager();

export default wsManager;
