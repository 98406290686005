import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import { ElNotification } from 'element-plus'
import 'element-plus/dist/index.css'
import App from './App.vue'
import router from './router'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import api from './service/axios.js'
import { calcTraffic, calcTime } from './service/common.js'
import wsManager from '@/service/wsManager.js'

const app = createApp(App)
const navigate = (path) => {
  return router.push({ path: path })
}
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

app.config.globalProperties.$nav = navigate
app.config.globalProperties.$notify = ElNotification
app.config.globalProperties.$api = api
app.config.globalProperties.$calcTraffic = calcTraffic
app.config.globalProperties.$calcTime = calcTime
app.config.globalProperties.$imgError = (e) => {
  //todo: report
}
app.config.globalProperties.$ws = wsManager
app.use(router)
app.use(ElementPlus)
app.mount('#app')