import axios from 'axios'
import { store } from './store.js'
import { getvisitorId } from './common.js'
import { ElNotification } from 'element-plus'

let config = {
    baseURL: store.backend,
    timeout: 30000,
    withCredentials: false,
}
const instance = axios.create(config)
instance.interceptors.request.use(async config => {
    config.headers['Content-Type'] = 'multipart/form-data'
    store.loadingCount++
    let allowNoToken = [
        '/Config/base',
        '/Config/report',
        '/Users/loginSubmit',
        '/Users/forgotSubmit',
        '/Users/regSubmit',
        '/Users/trustLogin',
    ]
    let length = allowNoToken.length
    let allow = false
    for (var i = 0; i < length; i++) {
        if (config.url === allowNoToken[i]) {
            allow = true
            break
        }
    }
    if (allow === false) {
        if (store.token) {
            let token = store.token
            let deviceId = localStorage.getItem('DeviceId')
            while (!deviceId) {
                await new Promise(resolve => setTimeout(resolve, 100))
                deviceId = localStorage.getItem('DeviceId')
            }
            config.headers['Authorization'] = `${token}`
            config.headers['DeviceId'] = `${deviceId}`
        } else {
            let redirect = ''
            if (window.location.pathname) {
                redirect = window.location.pathname
            }
            if (window.location.search) {
                redirect += window.location.search
            }
            if (redirect) {
                return window.location.href = '/Users/login?_uri=' + encodeURIComponent(redirect)
            } else {
                return window.location.href = '/Users/login'
            }
        }
    }
    return config
}, err => {
    store.loadingCount--
    ElNotification({
        message: err,
        type: 'error'
    })
    return
});
instance.interceptors.response.use(res => {
    store.loadingCount--
    if (res.data.success !== true) {
        ElNotification({
            message: res.data.msg,
            type: 'error'
        })
        return
    }
    return res.data
}, err => {
    store.loadingCount--
    if (!(err.response)) {
        ElNotification({
            message: err.message,
            type: 'error'
        })
        return
    }
    switch (err.response.status) {
        case 400:
            localStorage.removeItem('DeviceId')
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    instance({
                        ...err.config,
                        headers: {
                            ...err.config.headers
                        }
                    }).then(resolve).catch(reject);
                }, 500)
            });
            break;
        case 401:
            localStorage.removeItem('token')
            localStorage.removeItem('DeviceId')
            let redirect = ''
            if (window.location.pathname) {
                redirect = window.location.pathname
            }
            if (window.location.search) {
                redirect += window.location.search
            }
            if (redirect) {
                return window.location.href = '/Users/login?_uri=' + encodeURIComponent(redirect)
            } else {
                return window.location.href = '/Users/login'
            }
            break;
        default:
            console.log(err)
            ElNotification({
                message: err.message,
                type: 'error'
            })
            return
    }
});
export default instance